import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () =>
			import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
	},
	{
		path: '/search',
		name: 'Search',
		component: () =>
			import(/* webpackChunkName: "Search" */ '../views/Search.vue'),
		props: route => ({ query: route.query.q }),
	},
	{
		path: '/championships',
		name: 'Championships',
		component: () =>
			import(
				/* webpackChunkName: "Championships" */ '../views/Championships.vue'
			),
	},
	{
		path: '/championship/:slug',
		name: 'Championship',
		alias: '/championship/:slug/tickets',
		component: () =>
			import(
				/* webpackChunkName: "Championship" */ '../views/championship/Championship.vue'
			),
		meta: {
			min_nav: true,
			championship_nav: true,
		},
	},
	{
		path: '/championship/:slug/champinfo',
		name: 'ChampionshipChampInfo',
		component: () =>
			import(
				/* webpackChunkName: "Championship" */ '../views/championship/ChampionshipChampInfo.vue'
			),
		meta: {
			min_nav: true,
			championship_nav: true,
		},
	},
	{
		path: '/sport/:slug',
		name: 'Sport',
		component: () =>
			import(/* webpackChunkName: "Sport" */ '../views/Sport.vue'),
	},
	{
		path: '/documents/:year/:month/:day/:filepath',
		name: 'Documents',
		props: true,
		component: () =>
			import(/* webpackChunkName: "Document" */ '../views/Document.vue'),
	},
	{
		path: '/404',
		name: 'Missing',
		component: () =>
			import(/* webpackChunkName: "Missing" */ '../views/Missing.vue'),
	},
	{
		path: '*',
		name: 'Sportfile',
		props: getSlugFromRoute,
		component: () =>
			import(
				/* webpackChunkName: "Sportfile" */ '../views/Sportfile.vue'
			),
	},
];

function getSlugFromRoute(route) {
  const fullPathWithoutQueryParams = route.fullPath.split("?")[0]
	if (fullPathWithoutQueryParams.startsWith('/sports/')) {
		let _params = route.fullPath.split('/');
		return {
			year: _params[2],
			month: _params[3],
			day: _params[4],
			filename: _params[5],
		};
	}
	return { slug: fullPathWithoutQueryParams };
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.NODE_ENV === 'production' ? '/' : '/',
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
});

router.afterEach((to, from) => {
	let gtags = {
		'/championships': 'activ0',
		'/sport/baseball': 'activ003',
		'/championship/college-world-series': 'activ003',
		'/sport/football': 'activ00a',
		'/championship/fcs': 'activ00a',
		'/sport/mens-basketball': 'activ00',
		'/championship/march-madness': 'activ00',
		'/sport/mens-ice-hockey': 'activ001',
		'/championship/mens-frozen-four': 'activ001',
		'/sport/mens-lacrosse': 'activ002',
		'/championship/mens-lacrosse': 'activ002',
		'/sport/mens-soccer': 'activ005',
		'/championship/mens-college-cup': 'activ005',
		'/sport/outdoor-track-and-field': 'activ007',
		'/championship/trackfield-outdoor': 'activ007',
		'/sport/womens-basketball': 'activ000',
		'/championship/womens-final-four': 'activ000',
		'/sport/beach-volleyball': 'activ00c',
		'/championship/beach-volleyball': 'activ00c',
		'/sport/softball': 'activ00b',
		'/championship/womens-college-world-series': 'activ00b',
		'/sport/womens-gymnastics': 'activ004',
		'/championship/womens-gymnastics': 'activ004',
		'/sport/womens-lacrosse': 'activ006',
		'/championship/womens-lacrosse': 'activ006',
		'/sport/wrestling': 'activ00-',
		'/championship/wrestling': 'activ00-',
		'/sport/womens-soccer': 'activ008',
		'/championship/womens-college-cup': 'activ008',
		'/sport/womens-volleyball': 'activ009',
		'/championship/womens-volleyball': 'activ009',
	};

	if (window.gtag) {
		if (to.name === 'Missing' && from && from.fullPath != '/') {
			try {
				window.gtag('event', 'page_view', {
					page_location: window.location.href,
					page_path: to.fullPath + '?url=' + from.fullPath,
				});
			} catch (e) {
				console.warn(e);
			}
		} else {
			try {
				window.gtag('event', 'page_view', {
					page_location: window.location.href,
					page_path: to.fullPath,
				});
				if (
					to.name === 'Championship' ||
					to.name === 'Championships' ||
					to.name === 'Sport'
				) {
					let conversion_category = gtags[to.fullPath];

					window.gtag('event', 'conversion', {
						allow_custom_scripts: true,
						send_to: `DC-6098788/ncaas0/${conversion_category}+standard`,
					});
				}
			} catch (e) {
				console.warn(e);
			}
		}
	}

	try {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'dataLayerLoaded',
			non_interaction_flag: true,
			gaPropertyID: 'UA-195103076-13',
			schoolAttributes: {
				Division: 'SPECIALPROJECTS',
				ssClientID: '1925',
			},
		});
	} catch (e) {
		console.warn(e);
	}
});

export default router;
